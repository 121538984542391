import {
  AccessTime,
  AccessTimeFilled,
  Add,
  Bookmark,
  BookmarkBorder,
  ChevronLeft,
  Dns,
  DnsOutlined,
  FactCheck,
  FactCheckOutlined,
  Groups,
  GroupsOutlined,
  Person,
  PersonOutline,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { useAuth } from '../hooks/use-auth';
import { generateUuid } from '../modules/common';
import { NavLink } from 'react-router-dom';

const ExtendedDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const MenuDrawer = ({ open, setOpen }: Props) => {
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const auth = useAuth();
  const menus = [
    {
      to: '/download',
      listItemIcon: <Add />,
      activeListItemIcon: <Add />,
      listItemText: '新規条件作成',
      role: ['admin', 'leader'],
    },
    {
      to: '/queries',
      listItemIcon: <BookmarkBorder />,
      activeListItemIcon: <Bookmark />,
      listItemText: (
        <Typography>
          保存した
          <br />
          データ抽出条件
        </Typography>
      ),
      role: ['admin', 'leader', 'general'],
    },
    {
      to: '/download_history',
      listItemIcon: <AccessTime />,
      activeListItemIcon: <AccessTimeFilled />,
      listItemText: 'データ抽出履歴',
      role: ['admin', 'leader', 'general', 'notAffiliation'],
    },
    {
      to: '/datasources',
      listItemIcon: <DnsOutlined />,
      activeListItemIcon: <Dns />,
      listItemText: 'データソース一覧',
      role: ['admin'],
    },
    {
      to: '/users',
      listItemIcon: <PersonOutline />,
      activeListItemIcon: <Person />,
      listItemText: 'ユーザー一覧',
      role: ['admin', 'leader', 'general', 'notAffiliation'],
    },
    {
      to: '/groups',
      listItemIcon: <GroupsOutlined />,
      activeListItemIcon: <Groups />,
      listItemText: 'グループ一覧',
      role: ['admin', 'leader'],
    },
    {
      to: '/license',
      listItemIcon: <FactCheckOutlined />,
      activeListItemIcon: <FactCheck />,
      listItemText: 'ライセンス',
      role: ['superadmin'],
    },
  ];

  return (
    <ExtendedDrawer
      variant="permanent"
      open={open}
      PaperProps={{ sx: { backgroundColor: '#263238' } }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      {/* <Divider /> */}
      <List
        component="nav"
        sx={{
          mt:
            auth.license?.licenseKey && (auth.license?.isExpired || auth.license.isApproaching)
              ? 10
              : 2,
        }}
      >
        {menus.map((menu) => {
          const role = Array.from(new Set(auth.leaderGroupList.map((item) => item.role)));
          // グループ未所属の場合はnotAffiliationとする
          if (!role.length) {
            role.push('notAffiliation');
          }
          role.push(auth.role);
          if (auth.superadmin) {
            role.push('superadmin');
          }
          let show = false;
          for (const i of menu.role) {
            if (role.includes(i)) {
              show = true;
            }
          }

          // ライセンス未登録の場合はライセンスメニューのみ表示する
          if (!auth.license?.licenseKey && menu.to !== '/license') {
            return;
          }

          return (
            <div key={generateUuid()}>
              {show ? (
                <NavLink to={menu.to} style={{ textDecoration: 'none' }}>
                  {({ isActive }) => (
                    <Box sx={{ padding: '1px 8px' }}>
                      <ListItem
                        sx={{
                          borderRadius: '4px',
                          background: isActive ? '#455A64' : '',
                          ':hover': {
                            background: '#37474F',
                          },
                        }}
                      >
                        <ListItemIcon sx={{ color: '#FFFFFF' }}>
                          {isActive ? menu.activeListItemIcon : menu.listItemIcon}
                        </ListItemIcon>
                        <ListItemText primary={menu.listItemText} sx={{ color: '#FFFFFF' }} />
                      </ListItem>
                    </Box>
                  )}
                </NavLink>
              ) : null}
            </div>
          );
        })}
      </List>
    </ExtendedDrawer>
  );
};
