import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAuth } from '../hooks/use-auth';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from '../hooks/SnackBar';
import { useProgress } from '../hooks/useProgress';

const theme = createTheme({
  palette: {
    background: {
      default: '#E4E4E4',
    },
  },
});

export default function SignUp() {
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();

  const auth = useAuth();
  const signIn = async () => {
    showProgress(true);
    const result = await auth.signIn(formik.values.email, formik.values.password);
    if (!result.success) {
      showProgress(false);
      showSnackbar(result.message, 'error');
    }
  };

  // サインイン後権限に応じて画面遷移先を制御する
  useEffect(() => {
    if (auth.frontendUserRole !== 'pending') {
      showProgress(false);
      if (auth.frontendUserRole === 'notAffiliation') {
        window.location.href = '/download_history';
      } else if (auth.frontendUserRole === 'superAdmin') {
        window.location.href = '/license';
      } else if (auth.frontendUserRole === 'read') {
        window.location.href = '/queries';
      } else {
        window.location.href = '/download';
      }
    }
  }, [auth.role]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = yup.object({
    email: yup.string().email('メールアドレスを入力してください。').required('必須項目です。'),
    password: yup.string().required('必須項目です。'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      signIn();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item xs={12}>
            <CssBaseline />
            <Box
              sx={{
                width: 500,
                marginTop: 14,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#FFFFFF',
                padding: 2,
              }}
            >
              <Grid
                container
                justifyContent="center"
                sx={{
                  paddingTop: 6,
                  paddingBottom: 3,
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/cs_analytics_v.png`}
                  style={{ width: '200px', height: 'auto' }}
                />
              </Grid>

              <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      メールアドレス <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      type="email"
                      placeholder="メールアドレスを入力"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      パスワード <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      placeholder="パスワードを入力"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} alignItems="end" direction="column">
                  <Grid item xs={12}>
                    <NavLink to={'/passwordreset'}>
                      <Typography variant="body2">パスワードをお忘れの方</Typography>
                    </NavLink>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={Object.keys(formik.errors).length > 0}
                >
                  サインイン
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
