const Auth = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  region: (window as any)._env_.REACT_APP_AUTH_REGION,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userPoolId: (window as any)._env_.REACT_APP_AUTH_USER_POOL_ID,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userPoolClientId: (window as any)._env_.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_SRP_AUTH',
};

export default Auth;
