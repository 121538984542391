import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import React from 'react';

type Props = {
  isOpen: boolean;
  tile: string;
  children: React.ReactNode;
  okLabel?: string;
  okFunc?: () => void;
  okNode?: React.ReactNode;
  cancelFunc: () => void;
  maxWidth?: Breakpoint;
};
const ConfirmDialog: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.cancelFunc}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={props.maxWidth}
    >
      <DialogTitle id="alert-dialog-title">{props.tile}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.cancelFunc} color="primary" sx={{ marginRight: 1 }}>
          キャンセル
        </Button>

        {props.okLabel ? (
          <Button onClick={props.okFunc} color="primary" autoFocus>
            {props.okLabel}
          </Button>
        ) : null}

        {props.okNode ?? null}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
