import { fetchAuthSession } from 'aws-amplify/auth';
import React from 'react';
import { SubscribeMessage } from '../common/types/Responses';
import { keysToCamel } from '../common/func/converter';

type Subscriber<R = unknown> = (message: R) => void | (() => void);

type WebSocketClientConfig = {
  urls: string[];
};

const LOCAL_STORAGE_KEY = 'downloadNotificationMessages';

export class WebSocketClient {
  private readonly config: WebSocketClientConfig;
  private readonly subscribers = new Map<string, Set<Subscriber>>();
  private readonly websockets = new Map<string, WebSocket>();
  public data: { [url: string]: unknown } | undefined;
  public deletedMessages: SubscribeMessage[] = [];

  constructor(config: WebSocketClientConfig) {
    this.config = config;
    const { urls } = config;
    urls.forEach((url) => {
      this.subscribers.set(url, new Set());
    });
  }

  // 接続先のURLに対し接続を開始し、メッセージ受信時に実行されるイベントリスナを登録
  // 受信したデータをストアに保持しつつ、登録されたサブスクライバを実行
  open = async () => {
    const { urls } = this.config;
    const idToken = (await fetchAuthSession()).tokens?.idToken;

    urls.forEach((url) => {
      const ws = new WebSocket(url, idToken ? [idToken?.toString()] : undefined);
      ws.addEventListener('message', (event: MessageEvent<string>) => {
        const data = keysToCamel(JSON.parse(event.data)) as SubscribeMessage;

        this.data = { ...this.data, [url]: data };
        this.subscribers.get(url)?.forEach((s) => s(data));
      });

      ws.addEventListener('close', () => {
        // 切断されたら再度接続する
        this.open();
      });

      this.websockets.set(url, ws);
    });
  };

  // ストアが保持している現在の値を返す
  get = <R,>(url: string) => this.data?.[url] as R;

  // 接続先のURL毎にサブスクライバの登録
  subscribe = (url: string, subscriber: Subscriber | Subscriber[]) => {
    const target = this.subscribers.get(url);
    if (target) {
      if (Array.isArray(subscriber)) {
        subscriber.forEach((s) => target.add(s));
      } else {
        target.add(subscriber);
      }
    }
  };

  // 接続先のURL毎にサブスクライバを解除
  unsubscribe = (url: string, subscriber: Subscriber | Subscriber[]) => {
    const target = this.subscribers.get(url);
    if (target) {
      if (Array.isArray(subscriber)) {
        subscriber.forEach((s) => target.delete(s));
      } else {
        target.delete(subscriber);
      }
    }
  };

  // 接続先のクローズ
  close = () => {
    this.websockets.forEach((w) => {
      w.close();
    });
    this.subscribers.forEach((s) => s.clear());
  };

  // localStorageに重複がないようにメッセージを登録する
  setMessages = (newMessages: SubscribeMessage[]) => {
    const oldMessages = this.getMessages().messages;

    const mergeMessages = [...oldMessages, ...newMessages].reduce(
      (acc: SubscribeMessage[], obj) => {
        if (!acc.some((item: SubscribeMessage) => item.timestamp === obj.timestamp)) {
          acc.push(obj);
        }
        return acc;
      },
      []
    );

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(mergeMessages));
  };

  // localStorageのメッセージを取得
  getMessages = () => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    const messages = value ? (JSON.parse(value) as SubscribeMessage[]) : ([] as SubscribeMessage[]);
    const sortedMessages = messages.sort((a, b) => {
      return parseInt(b.timestamp) - parseInt(a.timestamp);
    });
    return {
      // 未読のメッセージ数
      newMessageNumber: messages.filter((message) => !message.read).length,
      // すべてのメッセージ
      messages: sortedMessages,
    };
  };

  // 一度表示したメッセージは既読にする
  readMessages = () => {
    const messages = localStorage.getItem(LOCAL_STORAGE_KEY);
    const resultMessages = messages
      ? (JSON.parse(messages) as SubscribeMessage[])
      : ([] as SubscribeMessage[]);
    resultMessages.map((message) => {
      message.read = true;
    });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(resultMessages));
  };

  // 特定のメッセージをlocalStorageから削除
  deleteMessage = (deleteMessage: SubscribeMessage) => {
    const messages = this.getMessages().messages;
    const resultMessages: SubscribeMessage[] = [];
    messages.map((message) => {
      if (message.timestamp !== deleteMessage.timestamp) {
        resultMessages.push(message);
      }
    });
    this.deletedMessages.push(deleteMessage);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(resultMessages));
  };

  // すべてのメッセージをlocalStorageから削除
  deleteAllMessage = () => {
    this.deletedMessages = this.getMessages().messages;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify([]));
  };
}

type WebSocketProviderProps = {
  client: WebSocketClient;
  children: React.ReactNode;
};

const WebSocketContext = React.createContext<WebSocketClient | null>(null);

export const WebSocketProvider = ({ client, children }: WebSocketProviderProps) => {
  React.useEffect(() => {
    client.open();
    return () => {
      client.close();
    };
  }, [client]);

  return <WebSocketContext.Provider value={client}>{children}</WebSocketContext.Provider>;
};

export const useWebSocketClient = () => {
  const client = React.useContext(WebSocketContext);
  if (!client) {
    throw new Error('Context is null.');
  }
  return client;
};

export const useWebSocket = <R = string,>(url: string, onMessage: (message: R) => void) => {
  const client = useWebSocketClient();

  const subscribe = React.useCallback(
    (onStoreChange: () => void) => {
      client.subscribe(url, [onMessage as Subscriber, onStoreChange]);
      return () => {
        client.unsubscribe(url, [onMessage as Subscriber, onStoreChange]);
      };
    },
    [client, url, onMessage]
  );

  const getSnapshot = React.useCallback(() => client.get<R>(url), [client, url]);

  const data = React.useSyncExternalStore(subscribe, getSnapshot);
  if (data) {
    const newMessage = keysToCamel(data) as SubscribeMessage;

    if (
      client.deletedMessages.filter(
        (deletedMessage) => deletedMessage.timestamp === newMessage.timestamp
      ).length
    ) {
      return;
    }
    client.setMessages([newMessage]);
  }

  return {
    data,
  };
};
