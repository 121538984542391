import * as yup from 'yup';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';
import {
  FormikErrors,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
  useFormik,
} from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  GetQueryResp,
  PostQueryReq,
  PostPreviewColumn,
  PostPreviewReq,
  PostTempDownloadReq,
  PutQueryReq,
  SchemaTableSetting,
  SimpleQueryReq,
  EnclosedText,
} from '../../common/types/Responses';

import { useNavigate } from 'react-router-dom';
import jwtAxios from '../../common/axios';
import { useColumnsSwr } from '../../common/swr/useColumnsSwr';
import { useConnectionsSwr } from '../../common/swr/useConnectionsSwr';
import { useTablesSwr } from '../../common/swr/useTablesSwr';
import { useSnackbar } from '../../hooks/SnackBar';
import OrderByConditionParts from './OrderByConditionParts';
import Progress from './Progress';
import WhereConditionParts from './WhereConditionParts';
import SchemaConditionParts from './SchemaConditionParts';
import SelectConditionParts from './SelectConditionParts';
import { generateUuid } from '../../modules/common';
import { BookmarkAddOutlined } from '@mui/icons-material';
import { BaseDataGrid } from '../BaseDataGrid';
import { useProgress } from '../../hooks/useProgress';
import ConfirmDialogDesregard from './ConfirmDialogDesregard';
import { useConnectionSwr } from '../../common/swr/useConnectionSwr';
import { keysToCamel, keysToSnake } from '../../common/func/converter';
import { useSWRConfig } from 'swr';
import { useQueriesSwr } from '../../common/swr/useQueriesSwr';
import { VariantType, useSnackbar as useNotistackSnackbar } from 'notistack';
import { ENCLOSED_TEXT_OPTIONS } from '../../common/const/enclosedText';
import { useAuth } from '../../hooks/use-auth';
import SqlPreviewDialog from './SqlPreviewDialog';

const validationSchema = yup.object({
  datasource: yup.string().required('データソース名は必須です。'),
  schemaN: yup.string().required('スキーマは必須です。'),
  tableN: yup.string().required('テーブルは必須です。'),
  queryName: yup
    .string()
    .required('タイトルは必須です。')
    .max(30, 'タイトルは30文字以下で入力してください。'),
  description: yup.string().max(300, '説明は300文字以下で入力してください。'),
  simple: yup.object({
    select: yup.array().of(yup.string()).required(),
    where: yup.array().required(),
    orderby: yup.array().required(),
  }),
  enclosedText: yup.string().required('ストリング値の引用符は必須です。'),
});

type Props = {
  query?: GetQueryResp;
};

export default function CreateSimpleQueryForm({ query }: Props) {
  const auth = useAuth();
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();
  const { enqueueSnackbar } = useNotistackSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogSqlOpen, setDialogSqlOpen] = useState<boolean>(false);

  const [dataSourceType, setDataSourceType] = useState<string>('');

  const { mutate } = useQueriesSwr();
  const { cache } = useSWRConfig();

  // セレクトのラベルを非表示にする
  const [hideSelectLabel, setHideSelectLabel] = useState(false);
  const [hideEnclosedTextSelectLabel, setHideEnclosedTextSelectLabel] = useState(false);

  // プレビューのローディング
  const [previewLoading, setPreviewLoading] = useState(false);

  // 条件保存、ダウンロードボタンのdisabled制御
  const [submitButton, setSubmitButton] = useState(false);

  // preview
  const [preview, setPreview] = useState(false);
  const [previewColumn, setPreviewColumn] = useState<GridColDef[]>([]);
  const [previewRow, setPreviewRow] = useState<GridRowsProp>([]);

  const queryNameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  // formik
  const initialSimpleQueryReq: SimpleQueryReq = {
    uuid: query ? query.uuid : '',
    datasource: query ? query.datasource : '',
    queryName: query ? query.queryName : '',
    description: query ? query.description : '',
    schemaN: query ? query.schemaN : '',
    tableN: query ? query.tableN : '',
    simple: {
      select: query && query.simple ? query.simple?.select : [],
      where: query && query.simple ? query.simple?.where : [],
      orderby: query && query.simple ? query.simple?.orderby : [],
    },
    enclosedText: query && query.enclosedText ? query.enclosedText : EnclosedText.None,
    csvBomOption: query ? query.csvBomOption : true,
  };

  const formik = useFormik({
    initialValues: initialSimpleQueryReq,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values;
    },
  });

  // APIs
  const {
    connections,
    isLoading: connectionsIsLoading,
    isError: connectionsIsError,
  } = useConnectionsSwr();

  const {
    connection,
    error: connectionIsError,
    isLoading: connectionIsLoading,
    isValidating: connectionIsValidating,
  } = useConnectionSwr(
    formik.values.datasource,
    dataSourceType,
    formik.values.datasource != '' && dataSourceType != ''
  );

  const {
    tables,
    isLoading: tablesIsLoading,
    isError: tablesIsError,
  } = useTablesSwr(
    formik.values.datasource ? [formik.values.datasource] : [],
    formik.values.datasource !== ''
  );

  const { columns, isError: columnsIsError } = useColumnsSwr(
    formik.values.datasource,
    formik.values.schemaN,
    formik.values.tableN
  );

  // swr error
  useEffect(() => {
    if (connectionsIsError)
      showSnackbar(
        `データソース一覧取得APIエラー (${connectionsIsError.response.data['detail']})`,
        'error'
      );
    if (connectionIsError)
      showSnackbar(
        `データソース取得APIエラー (${connectionIsError.response.data['detail']})`,
        'error'
      );
    if (tablesIsError)
      showSnackbar(`テーブル一覧取得APIエラー (${tablesIsError.response.data['detail']})`, 'error');
    if (columnsIsError)
      showSnackbar(`カラム一覧取得APIエラー (${columnsIsError.response.data['detail']})`, 'error');
  }, [connectionsIsError, connectionIsError, tablesIsError, columnsIsError, showSnackbar]);

  const schemaColumns: GridColDef[] = [
    {
      field: 'schemaN',
      headerName: 'スキーマ',
      flex: 1,
    },
    {
      field: 'tableN',
      headerName: 'テーブル',
      flex: 1,
    },
  ];

  const schemaRows: SchemaTableSetting[] = [];

  tables.map((schemas) => {
    schemas.map((schema) => {
      schema.tables.map((table: string) => {
        schemaRows.push({ schemaN: schema.schemaN, tableN: table });
      });
    });
  });

  useEffect(() => {
    // 行データ
    const newSchemaNames: string[] = [];
    const newTableNames: string[] = [];
    tables?.map((table) => {
      table?.map((schema) => {
        newSchemaNames.push(schema.schemaN);
        schema.tables.map((tableName) => {
          newTableNames.push(tableName);
        });
      });
    });
  }, [tablesIsLoading]);

  const handleScrollToFirstError = (error: FormikErrors<FormikValues>) => {
    const fieldOrdered: string[] = ['queryName', 'description'];

    // find first error from formik
    const firstErrorField = fieldOrdered.find((i) => !!error[i]);

    // map field with elementRef
    const mapFieldRef: Record<string, React.RefObject<HTMLElement>> = {
      queryName: queryNameRef,
      description: descriptionRef,
    };
    if (!firstErrorField) return;

    // find first element with id
    const errorElement = mapFieldRef[firstErrorField];

    // scroll into view with element
    if (errorElement) errorElement.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handlePreview = () => {
    formik
      .validateForm()
      .then((error) => {
        if (Object.keys(error).length !== 0) {
          console.error(error);
          formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
          handleScrollToFirstError(error);
        } else {
          setPreviewLoading((prevLoading) => !prevLoading);

          const params: PostPreviewReq = {
            datasource: formik.values.datasource,
            queryName: formik.values.queryName,
            description: formik.values.description,
            schemaN: formik.values.schemaN,
            tableN: formik.values.tableN,
            simple: formik.values.simple,
            enclosedText: formik.values.enclosedText,
          };
          jwtAxios
            .post('/api/preview/', keysToSnake(params))
            .then((response) => {
              const previewColumns = response.data.columns.map((c: PostPreviewColumn) =>
                keysToCamel(c)
              );
              const columns: GridColDef[] = [];
              previewColumns.forEach((column: PostPreviewColumn) => {
                const columnDef: GridColDef = {
                  field: `${column.name}`,
                  headerName: `${column.name} (${column.dataType[0].type})`,
                  flex: 1,
                  minWidth: 200,
                };
                columns.push(columnDef);
              });

              const rows: GridValidRowModel[] = [];
              response.data.results.forEach((result: GridRowsProp) => {
                const row: { [prop: string]: GridValidRowModel } = {};

                result.forEach((value, resultIndex) => {
                  const columnName = previewColumns[resultIndex].name;
                  row[columnName] = value;
                });
                rows.push(row);
              });
              setPreviewLoading((prevLoading) => !prevLoading);
              setPreview(true);
              setPreviewColumn(columns);
              setPreviewRow(rows);
              showSnackbar('プレビューを取得しました。', 'success');
            })
            .catch((error) => {
              setPreviewLoading((prevLoading) => !prevLoading);
              setPreview(false);
              setPreviewColumn([]);
              setPreviewRow([]);
              showSnackbar(
                `プレビューの取得に失敗しました。 (${error.response.data['detail']})`,
                'error'
              );
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickShowSqlPreview = () => {
    setDialogSqlOpen(true);
  };

  const handleTempDownload = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        showProgress(true);
        const req: PostTempDownloadReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          simple: formik.values.simple,
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };

        const variant: VariantType = 'success';
        enqueueSnackbar(`${formik.values.queryName}のデータ抽出を開始しました。`, { variant });

        jwtAxios
          .post('/api/temp_download/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`データ抽出に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePost = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        showProgress(true);
        const req: PostQueryReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          simple: formik.values.simple,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };
        jwtAxios
          .post('/api/query/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('設定を保存しました。', 'success');
            mutate();
            cache.delete(`api/queries/`);
            navigation('/queries');
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`設定の保存に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePut = () => {
    formik.validateForm().then((error) => {
      if (formik.values.uuid === undefined) {
        return;
      }
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        showProgress(true);
        const req: PutQueryReq = {
          uuid: formik.values.uuid,
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          simple: formik.values.simple,
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };
        jwtAxios
          .put('/api/query/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('設定を更新しました。', 'success');
            mutate();
            cache.delete(`api/queries/`);
            navigation('/queries');
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`設定の更新に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const getConnectionTypeById = (connectionId: string): string => {
    return connections.find((item) => item.connectionId === connectionId)?.type ?? '';
  };

  const handleChangeDataSource = (event: SelectChangeEvent) => {
    const connectionId = event.target.value;
    const connectionType = getConnectionTypeById(connectionId);

    formik.setFieldValue('datasource', connectionId);

    // スキーマとテーブル名 リセット
    formik.setFieldValue('schemaN', '');
    formik.setFieldValue('tableN', '');
    // 抽出する項目の設定 リセット
    formik.setFieldValue('simple.select', []);
    // 抽出条件の設定 リセット
    formik.setFieldValue('simple.where', []);
    // 並び順の設定 リセット
    formik.setFieldValue('simple.orderby', []);
    setDataSourceType(connectionType);
  };

  const handleChangeEnclosedText = (event: SelectChangeEvent) => {
    const enclosedText = event.target.value;
    formik.setFieldValue('enclosedText', enclosedText);
  };

  const handleChangeCsvBomOption = (event: ChangeEvent, checked: boolean) => {
    formik.setFieldValue('csvBomOption', checked);
  };

  const onBlurQueryName = () => {
    formik.setFieldTouched('queryName');
  };

  const onBlurDescription = () => {
    formik.setFieldTouched('description');
  };

  const enableSqlButton =
    !auth.license?.isExpired &&
    formik.values.datasource &&
    formik.values.schemaN &&
    formik.values.tableN;

  useEffect(() => {
    if (
      formik.values.queryName &&
      formik.values.datasource &&
      formik.values.schemaN &&
      formik.values.tableN
    ) {
      setSubmitButton(true);
    } else {
      setSubmitButton(false);
    }
  }, [
    formik.values.queryName,
    formik.values.datasource,
    formik.values.schemaN,
    formik.values.tableN,
  ]);

  useEffect(() => {
    if (query) {
      const connectionType = getConnectionTypeById(query.datasource);
      setDataSourceType(connectionType);
    }
  }, [query, connections]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* タイトル */}
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography sx={{ mt: 2 }}>
              タイトル <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              ref={queryNameRef}
              id="queryName"
              name="queryName"
              placeholder="例）東京、誕生日順"
              value={formik.values.queryName}
              onChange={formik.handleChange}
              error={formik.touched.queryName && Boolean(formik.errors.queryName)}
              helperText={formik.touched.queryName && formik.errors.queryName}
              fullWidth
              onBlur={onBlurQueryName}
            />
          </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>説明</Typography>
            <TextField
              ref={descriptionRef}
              id="description"
              name="description"
              placeholder="例）東京在住ユーザーの誕生日昇順"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              onBlur={onBlurDescription}
            />
          </Grid>
        </Grid>

        {/* データソース名 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>
              データソース名（データベース名）
              {query ? null : <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <FormControl fullWidth>
              {formik.values.datasource || hideSelectLabel ? null : (
                <InputLabel id="datasource-select-label">選択してください</InputLabel>
              )}
              <Select
                labelId="datasourceSelectLabel"
                id="datasource"
                value={connections.length ? formik.values.datasource : ''}
                onChange={handleChangeDataSource}
                onOpen={() => {
                  setHideSelectLabel(true);
                }}
                error={formik.touched.datasource && Boolean(formik.errors.datasource)}
                disabled={!!formik.values.uuid}
              >
                {connections.map((connection) => (
                  <MenuItem key={generateUuid()} value={connection.connectionId}>
                    {`${connection.name} (${
                      connectionIsLoading && connectionIsValidating
                        ? '取得中...'
                        : connection?.db || ''
                    })`}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {formik.touched.datasource && formik.errors.datasource}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {/* スキーマとテーブル名 */}
        <SchemaConditionParts
          formik={formik}
          columnsDef={schemaColumns}
          columnsRow={schemaRows ? schemaRows : []}
          isLoading={tablesIsLoading}
        />

        {/* 抽出する項目の設定 */}
        <SelectConditionParts formik={formik} columnsRow={columns ? columns : []} />

        {/* 抽出条件の設定 */}
        <WhereConditionParts formik={formik} columnsRow={columns ? columns : []} />

        {/* 並び順の設定 */}
        <OrderByConditionParts formik={formik} columnsRow={columns ? columns : []} />

        <Box marginTop={4}>
          <Button disabled={!enableSqlButton} variant="outlined" onClick={onClickShowSqlPreview}>
            SQLを表示する
          </Button>
          <SqlPreviewDialog
            isOpen={dialogSqlOpen}
            formik={formik}
            onClose={() => setDialogSqlOpen(false)}
          />
        </Box>

        {/* ストリング値の引用符 */}
        {dataSourceType === 'Snowflake' ? (
          <Grid container marginTop={4} justifyContent="center">
            <Grid item xs={12}>
              <Typography>
                ストリング値の引用符 <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControl fullWidth>
                {formik.values.enclosedText || hideEnclosedTextSelectLabel ? null : (
                  <InputLabel id="enclosed-text-select-label">選択してください</InputLabel>
                )}
                <Select
                  labelId="enclosedTextSelectLabel"
                  id="enclosedText"
                  value={connections.length ? formik.values.enclosedText : ''}
                  onChange={handleChangeEnclosedText}
                  onOpen={() => {
                    setHideEnclosedTextSelectLabel(true);
                  }}
                  error={formik.touched.enclosedText && Boolean(formik.errors.enclosedText)}
                >
                  {ENCLOSED_TEXT_OPTIONS.map((enclosedTextOption) => (
                    <MenuItem key={generateUuid()} value={enclosedTextOption.value}>
                      {enclosedTextOption.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched.enclosedText && formik.errors.enclosedText}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}

        {/* csvオプション */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>出力オプション</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="csvBomOption"
                  checked={formik.values.csvBomOption}
                  onChange={handleChangeCsvBomOption}
                />
              }
              label="CSVファイルをMS Excelで読み込む(サイズ上限1GB)"
            />
          </Grid>
        </Grid>

        {/* プレビュー */}
        <Paper elevation={0} sx={{ padding: 2, marginY: 4, backgroundColor: '#FAFAFA' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {preview && !previewLoading ? (
                <Paper>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <BaseDataGrid
                      rows={previewRow}
                      columns={previewColumn}
                      getRowIdFunc={(row: GridRowModel) => `${row.rowNum + generateUuid()}`}
                    />
                  </div>
                </Paper>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {previewLoading ? (
              <Grid item marginTop={2}>
                <CircularProgress color="inherit" size={20} />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Button
                variant="text"
                onClick={() => handlePreview()}
                fullWidth
                sx={{ marginTop: preview ? 2 : 0 }}
                disabled={
                  auth.license?.isExpired ? true : previewLoading || !submitButton ? true : false
                }
              >
                プレビュー
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end">
          <Box component="span" display="flex" justifyContent="space-evenly" alignItems="center">
            {formik.values.uuid === '' ? (
              <>
                <Button
                  type="submit"
                  variant="outlined"
                  startIcon={<BookmarkAddOutlined />}
                  onClick={() => handlePost()}
                  sx={{ marginRight: 2 }}
                  disabled={auth.license?.isExpired ? true : submitButton ? false : true}
                >
                  条件保存
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleTempDownload()}
                  disabled={auth.license?.isExpired ? true : submitButton ? false : true}
                >
                  データ抽出実行
                </Button>
              </>
            ) : (
              <>
                <Button sx={{ marginRight: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<BookmarkAddOutlined />}
                  onClick={() => handlePut()}
                  disabled={auth.license?.isExpired ? true : submitButton ? false : true}
                >
                  条件更新
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </form>
      <Progress open={connectionsIsLoading} />
      <ConfirmDialogDesregard
        isOpen={dialogOpen}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={() => navigation('/queries')}
      />
    </>
  );
}
