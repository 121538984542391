// DataSourcesとTablesをまとめた型
export type TableDetails = TableDetail[];

export type TableDetail = {
  connectionId: string;
  name: string;
  host: string;
  port: number;
  user: string;
  db: string;
  schemaName: string;
  tableName: string;
};

export type SchemaDetail = {
  connectionId: string;
  name: string;
  host: string;
  port: number;
  user: string;
  db: string;
  schemaName: string;
};

export type JobsResp = JobResp[];

export type JobResp = {
  userName: string;
  timestamp: string;
  status: string;
  objectName: string;
  createdAt: string;
  updatedAt: string;
  queryName: string;
  type: string;
  datasourceName: string;
  datasourceType: string;
  dbN: string;
  schemaN: string;
  tableN: string;
  simple?: SimpleQuery;
  query?: string;
  csvBomOption: boolean;
  downloadable: boolean;
};

export type GetPresignedUrlReq = {
  userName: string;
  timestamp: string;
};

export type PostDownloadReq = {
  userName: string;
  queryId: string;
};

export type PostTempDownloadReq = {
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  query?: string;
  simple?: SimpleQuery;
  enclosedText?: EnclosedText;
  csvBomOption: boolean;
};

export type GetConnectionResp = GetRedShiftGetConnectionResp | GetSnowFlakeGetConnectionResp;

export type GetRedShiftGetConnectionResp = {
  connectionId: string;
  name: string;
  host: string;
  port: number;
  user: string;
  passwd: string;
  db: string;
  redshiftIamRole: string;
  createdAt: string;
  updatedAt: string;
};

export type GetSnowFlakeGetConnectionResp = {
  connectionId: string;
  name: string;
  account: string;
  role: string;
  user: string;
  port: number;
  warehouse: string;
  db: string;
  schema: string;
  passwd: string;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export type ConnectionTestReq = ConnectionReq;

export type ConnectionReq = {
  // general params
  connectionId?: string;
  name: string;
  user: string;
  passwd: string;
  db: string;
  port: number | string;

  // redshift params
  host?: string;
  redshiftIamRole?: string;
  // snowflake params
  account?: string;
  role?: string;
  warehouse?: string;
  schema?: string;
};

export type GetQueriesResp = GetQueriesQuery[];

export type GetQueriesQuery = {
  uuid: string;
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  query?: string;
  simple?: SimpleQuery;
  csvBomOption: boolean;
  createdAt: string;
  updatedAt: string;
  lastDownloadAt: string;
  datasourceName: string;
  dbN: string;
  editable: boolean;
  deletable: boolean;
};

export type SimpleQuery = {
  select: string[];
  where: WhereCondition[];
  orderby: OrderByCondition[];
};

export type WhereCondition =
  | TimeComparisonCondition
  | TimeBetweenCondition
  | TextComparisonCondition
  | NumberComparisonCondition
  | BooleanComparisonCondition;

export type TimeComparisonCondition = {
  type: ConditionType['time'];
  column: string;
  operator: string;
  value: number;
  unit: TimeComparisonUnit;
};

export type TimeBetweenCondition = {
  type: ConditionType['time'];
  column: string;
  operator: 'between';
  values: string[] | null;
};

export type TextComparisonCondition = {
  type: ConditionType['text'];
  column: string;
  operator: TextComparisonOperator;
  value: string;
};

export type NumberComparisonCondition = {
  type: ConditionType['num'];
  column: string;
  operator: NumberComparisonOperator;
  value: string;
};

export type BooleanComparisonCondition = {
  type: ConditionType['bool'];
  column: string;
  value: BooleanComparisonValue;
};

export type OrderByCondition = {
  column: string;
  order: Order;
};

export type ConditionType = {
  time: 'time';
  text: 'text';
  num: 'num';
  bool: 'bool';
};

export type TimeComparisonUnit = 'year' | 'month' | 'day' | 'hour';

export type TextComparisonOperator = '=' | '!=' | 'LIKE' | 'NOT LIKE';

export type NumberComparisonOperator = '=' | '!=' | '<' | '<=' | '>' | '=>';

export type BooleanComparisonValue = 'true' | 'false';

export type Order = 'asc' | 'desc';

export type PostPreviewReq = {
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  query?: string;
  simple?: SimpleQuery;
  enclosedText?: EnclosedText;
};

export type PostPreviewResp = {
  columns: PostPreviewColumn[];
  results: string[][];
};

export type PostPreviewColumn = {
  name: string;
  dataType: PostPreviewColumnDataType[];
};

export type PostPreviewColumnDataType = {
  type: string;
  precision?: number;
};

export type GetQueryReq = {
  uuid: string;
};

export const EnclosedText = {
  None: 'NONE',
  SingleQuotation: 'SINGLE_QUOTATION',
  DoubleQuotation: 'DOUBLE_QUOTATION',
} as const;

// eslint-disable-next-line prettier/prettier
export type EnclosedText = typeof EnclosedText[keyof typeof EnclosedText];

export type EnclosedTextOption = {
  label: string;
  value: EnclosedText;
};

export type GetQueryResp = {
  uuid: string;
  datasource: string;
  queryName: string;
  description: string;
  query?: string;
  schemaN: string;
  tableN: string;
  simple?: SimpleQuery;
  createdAt: string;
  updatedAt: string;
  lastDownloadAt: string;
  enclosedText?: EnclosedText;
  csvBomOption: boolean;
};

export type PostQueryReq = {
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  query?: string;
  simple?: SimpleQuery;
  enclosedText?: EnclosedText;
  csvBomOption: boolean;
};
export type PutQueryReq = {
  uuid: string;
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  query?: string;
  simple?: SimpleQuery;
  enclosedText?: EnclosedText;
  csvBomOption: boolean;
};

export type GetColumnsResp = GetColumn[];

export type GetColumn = {
  name: string;
  dataType: [
    {
      type: string;
      precision: number;
      scale: number;
    },
  ];
};

export type SubscribeMessage = {
  queryName: string;
  updatedAt: string;
  userName: string;
  errorMsg: string;
  timestamp: string;
  downloadState: '1' | '2' | '3'; // 1:ダウンロード中, 2: 完了, 3:失敗
  statusCode?: number; // 401: Websocket ExpiredSignatureError
  read?: boolean;
};

export type SimpleQueryReq = {
  uuid?: string;
  datasource: string;
  queryName: string;
  description: string;
  schemaN: string;
  tableN: string;
  simple: SimpleQuery;
  enclosedText?: EnclosedText;
  csvBomOption: boolean;
};

export type SchemaTableSetting = {
  schemaN: string;
  tableN: string;
};

export type PostPutLicense = {
  licenseKey: string;
};
