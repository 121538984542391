import { Cancel, EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  PopoverActions,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  colors,
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  convertOrderByConditionToString,
  shortableOrderByConditionColumn,
} from '../../common/func/functions';
import { GetColumnsResp, OrderByCondition, SimpleQueryReq } from '../../common/types/Responses';
import { generateUuid } from '../../modules/common';
import { MAX_COLUMN_TEXT_LENGTH } from '../../common/const/maxTextLength';

type Props = {
  formik: FormikProps<SimpleQueryReq>;
  columnsRow: GetColumnsResp;
};

const OrderByConditionParts: React.FC<Props> = ({ formik, columnsRow: columnsRow }: Props) => {
  const [conditionSettingDialogOpen, setConditionSettingDialogOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedColumn, setSelectedColumn] = useState<{ name: string; type: string }>({
    name: '',
    type: '',
  });
  const [orderValue, setOrderByValue] = useState<'asc' | 'desc'>('asc');
  // target to show popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverAction = useRef<PopoverActions>(null);

  const [search, setSearch] = useState<string>('');

  const handleUpdatePopoverPosition = () => {
    // find scroll element (main)
    const mainElement = document.querySelector('main.MuiBox-root');
    setTimeout(() => {
      if (mainElement) {
        // get y & height of add button
        const anchorElY = anchorEl?.getBoundingClientRect().y ?? 0;
        const anchorElHeight = anchorEl?.getBoundingClientRect().height ?? 0;
        // caculate distance
        const distance = window.innerHeight - anchorElY - anchorElHeight - 320;

        // if button is over bottom by popup scroll button into view
        if (distance < 0) {
          mainElement.scrollTo({
            top: mainElement.scrollTop - distance,
            behavior: 'smooth',
          });
        }
      }
      setTimeout(() => {
        popoverAction.current?.updatePosition();
      }, 100);
    }, 100);
  };

  const onClickUpdateSettingOrderByCondition = (condition: OrderByCondition) => {
    onClickConditionSetting(condition.column);
  };

  // 削除ボタン
  const onClickDeleteOrderByCondition = (condition: OrderByCondition) => {
    const newConditions = formik.values.simple.orderby.filter(
      (stateCondition) => stateCondition !== condition
    );
    formik.setFieldValue('simple.orderby', newConditions);
  };

  const handleDialogOk = () => {
    if (selectionModel.length === 0) {
      // 初期化して終了
      setSelectedColumn({ name: '', type: '' });
      setOrderByValue('asc');
      return;
    } else if (selectedColumn.name === undefined) {
      // 初期化して終了
      setSelectedColumn({ name: '', type: '' });
      setOrderByValue('asc');
      return;
    } else {
      const newCondition: OrderByCondition = {
        column: selectedColumn.name,
        order: orderValue,
      };
      // すでにあれば更新し、なければ追加する
      const newConditions = formik.values.simple.orderby.map((stateCondition) => {
        if (stateCondition.column === newCondition.column) {
          return newCondition;
        } else {
          return stateCondition;
        }
      });
      const findedCondition = formik.values.simple.orderby.find(
        (stateCondition) => stateCondition.column === newCondition.column
      );
      if (findedCondition === undefined) {
        newConditions.push(newCondition);
      }

      formik.setFieldValue('simple.orderby', newConditions);
    }
  };

  // 条件句の切替
  const handleChangeOrderByValue = (event: SelectChangeEvent) => {
    if (event.target.value === 'asc' || event.target.value === 'desc') {
      setOrderByValue(event.target.value);
    }
  };

  const onClickConditionSetting = (name: string) => {
    if (name) {
      const targetColumn = columnsRow.find((row) => row.name === name);
      if (!targetColumn) {
        return;
      }
      setSelectedColumn({
        name: targetColumn.name,
        type: targetColumn.dataType[0].type,
      });
    } else {
      setSelectedColumn({ name: '', type: '' });
    }
    setSelectionModel([name]);

    setConditionSettingDialogOpen(true);
  };

  const onClickShowDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const notSelectedWithSearchColumn = columnsRow
    .filter((i) => !formik.values.simple.orderby.find((j) => j.column === i.name))
    .filter((i) => i.name.includes(search));

  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? 'order-by-condition-popover' : undefined;

  useEffect(() => {
    if (anchorEl && columnsRow.length) {
      handleUpdatePopoverPosition();
    }
  }, [anchorEl, formik.values.simple.orderby, columnsRow]);

  return (
    <>
      <Grid container marginTop={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography>並び順の設定</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {formik.values.simple.orderby.length ? (
            <List sx={{ border: '1px solid #EEEEEE', borderRadius: '5px', padding: 0 }}>
              {formik.values.simple.orderby.map((condition, index) => {
                return (
                  <div key={generateUuid()}>
                    <ListItem>
                      {condition.column.length > MAX_COLUMN_TEXT_LENGTH ? (
                        <Tooltip
                          placement="bottom-start"
                          sx={{ wordBreak: 'break-word' }}
                          title={convertOrderByConditionToString(condition)}
                        >
                          <ListItemText
                            sx={{ wordBreak: 'break-word' }}
                            primary={convertOrderByConditionToString(
                              shortableOrderByConditionColumn(condition)
                            )}
                          />
                        </Tooltip>
                      ) : (
                        <ListItemText
                          sx={{ wordBreak: 'break-word' }}
                          primary={convertOrderByConditionToString(
                            shortableOrderByConditionColumn(condition)
                          )}
                        />
                      )}
                      <Tooltip title="編集" placement="top">
                        <IconButton
                          onClick={() => {
                            onClickUpdateSettingOrderByCondition(condition);
                          }}
                          sx={{ marginRight: 1 }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除" placement="top">
                        <IconButton
                          onClick={() => {
                            onClickDeleteOrderByCondition(condition);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                    {formik.values.simple.orderby.length === index + 1 ? null : <Divider />}
                  </div>
                );
              })}
            </List>
          ) : null}
          <Button variant="text" aria-describedby={popoverId} onClick={onClickShowDropDown}>
            + 追加
          </Button>
          <Popover
            id={popoverId}
            action={popoverAction}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                maxHeight: 300,
                width: 'min(450px, 100%)',
                paddingX: 2,
                paddingBottom: 2,
                position: 'relative',
              },
            }}
          >
            {columnsRow.length ? (
              <>
                <Grid
                  container
                  sx={{
                    gap: 2,
                    mb: 1,
                    paddingTop: 2,
                    position: 'sticky',
                    top: 0,
                    backgroundColor: colors.common.white,
                    zIndex: 1,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      value={search}
                      onChange={onChangeSearch}
                      fullWidth
                      placeholder="項目を入力してください"
                    />
                  </Grid>
                </Grid>
                {notSelectedWithSearchColumn.map((row) => (
                  <MenuItem
                    onClick={() => {
                      onClickConditionSetting(row.name);
                    }}
                    key={row.name}
                    value={row.name}
                  >
                    <Box width="100%" flexDirection="row" display="flex">
                      <Box flex={1}>
                        <ListItemText
                          sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                          secondary={row.dataType[0].type}
                          primary={row.name}
                        />
                      </Box>
                      <Box sx={{ paddingLeft: 1 }}>
                        <ListItemText primary="条件設定" />
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </>
            ) : (
              <Box flex={1} padding={2} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" size={20} />
              </Box>
            )}
          </Popover>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="md"
        open={conditionSettingDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6" marginBottom={2}>
            条件設定
          </Typography>
          <Paper elevation={0} sx={{ backgroundColor: '#FAFAFA' }}>
            <Grid container padding={3} gap={2}>
              <Grid item xs={6}>
                <Typography variant="caption">カラム</Typography>
                <Typography sx={{ wordBreak: 'break-word' }}>{selectedColumn?.name}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography variant="caption">データ型</Typography>
                <Typography>{selectedColumn?.type}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Grid container paddingTop={2}>
            <Grid item xs={12}>
              <Box sx={{}}>
                <FormControl>
                  <Select
                    id="demo-simple-select"
                    value={orderValue}
                    onChange={handleChangeOrderByValue}
                  >
                    <MenuItem value={'asc'}>asc（昇順↑）</MenuItem>
                    <MenuItem value={'desc'}>desc（降順↓）</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ margin: 4 }}>
          <Button
            onClick={() => setConditionSettingDialogOpen(false)}
            color="inherit"
            sx={{ marginRight: 2 }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDialogOk(), setConditionSettingDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            追加
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderByConditionParts;
