import React, { ReactNode, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import { fetchAuthSession } from 'aws-amplify/auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BASE_DOMAIN = (window as any)._env_.REACT_APP_API_BASE_DOMAIN;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const API_PROTOCOL = (window as any)._env_.REACT_APP_API_PROTOCOL;

const jwtAxios = axios.create({
  baseURL: `${API_PROTOCOL}://${BASE_DOMAIN}/`, // YOUR_API_URL HERE

  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.error('Need to logout user: ', err);
    }
    return Promise.reject(err);
  }
);
export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
  }
};
interface AxiosClientProviderProps {
  children: ReactNode;
}
export const AxiosClientProvider: React.FC<AxiosClientProviderProps> = ({ children }) => {
  useEffect(() => {
    const requestInterceptors = jwtAxios.interceptors.request.use(
      //@ts-expect-error: Promise Error type
      async (config: AxiosRequestConfig) => {
        const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        if (config.headers) {
          config.headers.Authorization = `Bearer ${idToken}`;
        }
        return config;
      }
    );
    return () => {
      jwtAxios.interceptors.request.eject(requestInterceptors);
    };
  }, []);
  return <>{children}</>;
};

export default jwtAxios;
