import { Download, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import jwtAxios from '../../common/axios';
import { keysToCamel } from '../../common/func/converter';
import { serializeOrderBy, serializeWhere, utcToJstFormat } from '../../common/func/functions';
import { useJobsSwr } from '../../common/swr/useJobsSwr';
import { GetPresignedUrlReq, JobResp, JobsResp } from '../../common/types/Responses';
import { useSnackbar } from '../../hooks/SnackBar';
import { useAuth } from '../../hooks/use-auth';
import { useProgress } from '../../hooks/useProgress';
import { generateUuid } from '../../modules/common';
import Timer from '../Timer';
import ConfirmDialog from './ConfirmDialog';
import TablePaginationActions from './TablePaginationActions';

export default function DownloadHistoryTable() {
  const auth = useAuth();
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [download, setDownload] = useState({ url: '', left: 0 });
  // APIs
  const { jobs, isLoading, isError } = useJobsSwr();

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`ダウンロード履歴覧取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  const onClickDownload = (row: JobResp) => {
    showProgress(true);
    const params: GetPresignedUrlReq = {
      userName: row.userName,
      timestamp: row.timestamp,
    };
    jwtAxios
      .get(`api/presigned_url/?user_name=${params.userName}&timestamp=${params.timestamp}`)
      .then((result) => {
        const data = keysToCamel(result.data);
        toggleIsLicValidity(false);
        if (data.licmessage) {
          showSnackbar(`ライセンス有効期限が1ヶ月を切っています`, 'warning');
        }
        showProgress(false);
        const presignedUrl = data.presignedUrl;
        setDownload({ url: presignedUrl, left: 60 });
        setDialogOpen(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
        showProgress(false);
        showSnackbar('データの取得に失敗しました。' + error.toString(), 'error');
      });
  };

  const calcDuration = (start: string, end: string) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    let millisecond = startDate.diff(endDate, 'millisecond');
    if (millisecond < 1000) {
      // 1秒未満を1秒に丸める
      millisecond = 1000;
    }
    const duration = dayjs.utc(millisecond).format('HH:mm:ss');
    return <>{duration}</>;
  };

  function Row(props: { row: JobResp; onClickDownload: (row: JobResp) => void }) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    const datasourceProperties = [
      { データソース名: row.datasourceName },
      { データベース: row.dbN },
      { スキーマ: row.schemaN },
      { テーブル: row.tableN },
      {
        タイプ: `${row.query ? '高度な条件設定（SQL）' : 'シンプルな条件設定'}${
          row.csvBomOption ? '[E]' : ''
        }`,
      },
    ];

    return (
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover tabIndex={-1}>
          <TableCell sx={{ padding: '4px 16px', borderBottom: 'unset' }}>
            <Tooltip
              placement="bottom-start"
              sx={{ wordBreak: 'break-word' }}
              title={row.queryName}
            >
              <Typography
                sx={{
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '1',
                  display: '-webkit-box',
                }}
              >
                {row.queryName}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ padding: '4px 16px', borderBottom: 'unset' }}>
            <Box>
              {row.status === '1' ? <Chip variant="outlined" label={'データ抽出中'} /> : null}
              {row.status === '2' ? <Chip label={'完了'} /> : null}
              {row.status !== '1' && row.status !== '2' ? (
                <Chip variant="outlined" color="error" label={'失敗'} />
              ) : null}
            </Box>
          </TableCell>
          <TableCell sx={{ padding: '4px 16px', borderBottom: 'unset' }}>
            {row.status === '1' ? (
              <div>-</div>
            ) : (
              <div>
                <Typography>{calcDuration(row.updatedAt, row.createdAt)}</Typography>
                <Typography color="textSecondary">開始：{utcToJstFormat(row.createdAt)}</Typography>
                <Typography color="textSecondary">終了：{utcToJstFormat(row.updatedAt)}</Typography>
              </div>
            )}
          </TableCell>
          <TableCell align="right" sx={{ padding: '4px 16px', borderBottom: 'unset' }}>
            <>
              {!auth.license?.isExpired && row.status === '2' && row.downloadable ? (
                <Button
                  sx={{ marginRight: 2 }}
                  aria-label="download"
                  variant="outlined"
                  onClick={() => {
                    onClickDownload(row);
                  }}
                  name={row.userName}
                >
                  ダウンロード
                </Button>
              ) : (
                <></>
              )}
            </>
            <Tooltip title="詳細" placement="top">
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        minHeight: '100px',
                        backgroundColor: '#FAFAFA',
                        padding: 2,
                        margin: 1,
                      }}
                      elevation={0}
                    >
                      <Box sx={{ display: 'flex' }}>
                        {datasourceProperties.map((obj) => {
                          const key = Object.entries(obj).flat()[0];
                          const value = Object.entries(obj).flat()[1];
                          if (!value) {
                            return;
                          }
                          return (
                            <Box key={generateUuid()} sx={{ flex: '1' }}>
                              <Typography variant="caption">{key}</Typography>
                              <div>{value}</div>
                            </Box>
                          );
                        })}
                      </Box>
                    </Paper>
                  </Grid>
                  {row.query ? (
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          minHeight: '260px',
                          backgroundColor: '#FAFAFA',
                          padding: 2,
                          margin: 1,
                        }}
                        elevation={0}
                      >
                        <Typography variant="caption">SQL</Typography>
                        <div>{row.query}</div>
                      </Paper>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <Paper
                          sx={{
                            minHeight: '260px',
                            backgroundColor: '#FAFAFA',
                            padding: 2,
                            margin: 1,
                          }}
                          elevation={0}
                        >
                          <Typography variant="caption">抽出項目</Typography>
                          {row.simple?.select.length === 0 ? (
                            <div>全項目</div>
                          ) : (
                            <Typography
                              component="p"
                              sx={{ wordBreak: 'break-word' }}
                              variant="body2"
                            >
                              {row.simple?.select.join(', ')}
                            </Typography>
                          )}
                        </Paper>
                      </Grid>

                      <Grid item xs={6}>
                        <Paper
                          sx={{
                            minHeight: '260px',
                            backgroundColor: '#FAFAFA',
                            padding: 2,
                            margin: 1,
                          }}
                          elevation={0}
                        >
                          <Typography variant="caption">抽出条件</Typography>
                          <div>
                            {row.simple?.where ? (
                              <Typography
                                component="p"
                                sx={{ wordBreak: 'break-word' }}
                                variant="body2"
                              >
                                {serializeWhere(row.simple?.where)}
                              </Typography>
                            ) : null}

                            {row.simple?.orderby ? (
                              <Typography
                                component="p"
                                sx={{ wordBreak: 'break-word' }}
                                variant="body2"
                              >
                                {serializeOrderBy(row.simple?.orderby)}
                              </Typography>
                            ) : null}
                          </div>
                        </Paper>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  // ページング処理
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortRowsByCreatedAt = (rows: JobsResp) => {
    return rows.sort(function (a, b) {
      return a.createdAt > b.createdAt ? -1 : 1;
    });
  };

  return (
    <>
      <div style={{ display: 'flex', height: '100%' }}>
        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                backgroundColor: '#EEEEEE',
              }}
            >
              <TableRow>
                <TableCell>タイトル</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>抽出実行時間</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {(rowsPerPage > 0
                  ? sortRowsByCreatedAt(jobs).slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : sortRowsByCreatedAt(jobs)
                ).map((row) => (
                  <Row key={generateUuid()} row={row} onClickDownload={onClickDownload} />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}

            <TableFooter>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 1000]}
                  count={jobs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage={'表示件数'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>

      <ConfirmDialog
        isOpen={dialogOpen}
        tile={'CSVのダウンロード'}
        okNode={
          <Button
            variant="contained"
            startIcon={<Download />}
            href={download.url}
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            ダウンロード
          </Button>
        }
        cancelFunc={handleDialogClose}
        maxWidth={'xs'}
      >
        ダウンロード有効時間：残り
        <Timer seconds={download.left} doFinish={() => setDialogOpen(false)} />秒
      </ConfirmDialog>
    </>
  );
}
