import { Amplify } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import jwtAxios, { setAuthToken } from '../common/axios';
import {
  fetchAuthSession,
  signUp as signUpAws,
  signIn as signInAws,
  confirmSignUp as confirmSignUpAws,
  signOut as signOutAws,
  fetchUserAttributes,
  resetPassword,
  confirmResetPassword,
  updatePassword,
} from 'aws-amplify/auth';
import AwsConfigAuth from '../aws-config/Auth';
import { keysToCamel, keysToSnake } from '../common/func/converter';
import { FrontendUserRole, License, User } from '../common/types';

Amplify.configure({
  Auth: {
    Cognito: AwsConfigAuth,
  },
});

interface LeaderGroupList {
  groupId: string;
  role: string;
}

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  role: string;
  superadmin: boolean;
  userId: string;
  userName: string;
  email: string;
  leaderGroupList: LeaderGroupList[];
  frontendUserRole: FrontendUserRole;
  license: License | undefined;
  signUp: (username: string, password: string) => Promise<Result>;
  confirmSignUp: (verificationCode: string) => Promise<Result>;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => void;
  forgotPassword: (username: string) => Promise<Result>;
  forgotPasswordConfirm: (username: string, code: string, password: string) => Promise<Result>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<Result>;
  setLicenseDataRevalidateOnMount: (license: License) => void;
}

interface Result {
  success: boolean;
  message: string;
}

const authContext = createContext({} as UseAuth);

type Props = {
  children?: React.ReactNode;
};

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('');
  const [superadmin, setSuperadmin] = useState(false);
  const [userId, setUserId] = useState('');
  const [userName, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [leaderGroupList, setLeaderGroupList] = useState<LeaderGroupList[]>([]);
  const [password, setPassword] = useState('');
  const [frontendUserRole, setFrontendUserRole] = useState<FrontendUserRole>('pending');
  const [license, setLicense] = useState<License | undefined>();

  useEffect(() => {
    const fetcher = async () => {
      try {
        const { tokens: session } = await fetchAuthSession();
        const attributes = await fetchUserAttributes();

        setUserId(attributes.sub ?? '');
        setUsername(attributes.name ?? '');
        setEmail(attributes.email ?? '');
        setIsAuthenticated(true);
        setIsLoading(false);
        setAuthToken(session?.idToken?.toString() ?? '');
        setUserRole(attributes.sub ?? '');
        setLicenseData();
      } catch (error) {
        setRole('');
        setSuperadmin(false);
        setUserId('');
        setUsername('');
        setEmail('');
        setLeaderGroupList([]);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };
    fetcher();
  }, []);

  const isWithin30DaysExpiration = (expirationDate: string) => {
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30日分のミリ秒
    const expirationTimestamp = Date.parse(expirationDate);
    const currentTimestamp = Date.now();
    return expirationTimestamp - currentTimestamp < thirtyDaysInMillis;
  };

  const isLicenseExpired = (expirationDate: string) => {
    // 現在の日付を取得
    const today = new Date();
    // 1日前の日付を計算
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    // 1日前の最終時刻（23:59:59）をセット
    const expiredDateTime = yesterday.setHours(23, 59, 59, 999);

    return Date.parse(expirationDate) <= expiredDateTime;
  };

  const setLicenseDataRevalidateOnMount = (license: License) => {
    if (license.licenseKey) {
      license.isApproaching = isWithin30DaysExpiration(license.expireDate);
      license.isExpired = isLicenseExpired(license.expireDate);
    }
    setLicense(license);
  };

  const setLicenseData = async () => {
    try {
      await jwtAxios
        .get('/api/license/')
        .then(function (response) {
          const data = keysToCamel(response.data) as License;
          if (data.licenseKey) {
            data.isApproaching = isWithin30DaysExpiration(data.expireDate);
            data.isExpired = isLicenseExpired(data.expireDate);
          }
          setLicense(data);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const setUserRole = async (firstId: string) => {
    try {
      const param = {
        firstId,
      };
      await jwtAxios
        .get('/api/user/', { params: keysToSnake(param) })
        .then(function (response) {
          const data = keysToCamel(response.data) as User;
          setRole(data.adminRole === 'superadmin' ? 'admin' : data.adminRole);
          setSuperadmin(data.adminRole === 'superadmin');
          setLeaderGroupList(data.group);
          convertRoleToFrontendUserRole(data);
        })
        .catch(function (error) {
          console.error(error);
          setRole('');
          setSuperadmin(false);
          setLeaderGroupList([]);
        });
    } catch (error) {
      console.error(error);
    }
  };

  // フロントエンドで取り扱うユーザー権限に変換
  const convertRoleToFrontendUserRole = async (user: User) => {
    if (user.adminRole === 'superadmin') {
      setFrontendUserRole(FrontendUserRole.SuperAdmin);
    } else if (user.adminRole === 'admin') {
      setFrontendUserRole(FrontendUserRole.Admin);
    } else if (user.adminRole === 'notAdmin') {
      if (user.group.length) {
        const leaders = user.group.filter((g) => g.role === 'leader');
        if (leaders.length) {
          setFrontendUserRole(FrontendUserRole.Edit);
        } else {
          setFrontendUserRole(FrontendUserRole.Read);
        }
      } else {
        setFrontendUserRole(FrontendUserRole.NotAffiliation);
      }
    }
  };

  const signUp = async (username: string, password: string) => {
    try {
      await signUpAws({ username, password });
      setUsername(username);
      setPassword(password);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const confirmSignUp = async (verificationCode: string) => {
    try {
      await confirmSignUpAws({
        username: email,
        confirmationCode: verificationCode,
      });
      const result = await signIn(email, password);
      setPassword('');
      return result;
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const resultSignIn = await signInAws({
        username: email,
        password,
        options: {
          authFlowType: 'USER_SRP_AUTH',
        },
      });

      if (resultSignIn.isSignedIn) {
        const { tokens: session } = await fetchAuthSession();
        const attributes = await fetchUserAttributes();

        setUserId(attributes.sub ?? '');
        setUsername(attributes.name ?? '');
        setEmail(attributes.email ?? '');
        setIsLoading(false);
        setAuthToken(session?.accessToken.toString());
        setUserRole(attributes.sub ?? '');
      }
      return {
        success: true,
        message: '',
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: 'メールアドレスまたはパスワードが間違っています。',
      };
    }
  };

  const signOut = async () => {
    try {
      await signOutAws();
      setRole('');
      setSuperadmin(false);
      setUserId('');
      setUsername('');
      setLeaderGroupList([]);
      setPassword('');
      setEmail('');
      setIsAuthenticated(false);
      window.location.reload();
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      await resetPassword({ username });
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '確認コードの送信に失敗しました。',
      };
    }
  };

  const forgotPasswordConfirm = async (username: string, code: string, password: string) => {
    try {
      await confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword: password,
      });
      return {
        success: true,
        message: 'パスワードの再設定が完了しました。',
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: 'パスワードの再設定に失敗しました。',
      };
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      updatePassword({ oldPassword, newPassword });
      return {
        success: true,
        message: 'パスワードの変更が完了しました。',
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: 'パスワードの変更に失敗しました。',
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    role,
    superadmin,
    userId: userId,
    userName: userName,
    email,
    leaderGroupList,
    frontendUserRole,
    license,
    signUp,
    confirmSignUp,
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordConfirm,
    changePassword,
    setLicenseDataRevalidateOnMount,
  };
};
