import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AxiosClientProvider } from './common/axios/';
import DataSourceSettingPage from './pages/DataSourceSettingPage';
import DataSourcesPage from './pages/DataSourcesPage';
import DownloadHistoryPage from './pages/DownloadHistoryPage';
import { NotFound } from './pages/NotFound';
import DownloadPage from './pages/DownloadPage';
import ForgotPasswordConfirm from './pages/ForgotPasswordConfirm';
import GroupPage from './pages/GroupPage';
import GroupsPage from './pages/GroupsPage';
import PasswordReset from './pages/PasswordReset';
import CompletePasswordReset from './pages/CompletePasswordReset';
import QueriesPage from './pages/QueriesPage';
import SignIn from './pages/SignIn';
import SimpleQueryPage from './pages/SimpleQueryPage';
import { SnackbarContextProvider } from './hooks/SnackBar';
import SqlSettingPage from './pages/SqlSettingPage';
import UserPage from './pages/UserPage';
import UsersPage from './pages/UsersPage';
import { useAuth } from './hooks/use-auth';
import PasswordChangePage from './pages/PasswordChangePage';
import { ProgressContextProvider } from './hooks/useProgress';
import LicensePage from './pages/LicensePage';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { FrontendUserRole } from './common/types';
import RouteAuthGuard from './components/RouteAuthGuard';
import { Forbidden } from './pages/Forbidden';
import { CircularProgress, Grid } from '@mui/material';

export default function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <Grid container position={'absolute'} justifyContent="center" top={'50%'}>
        <CircularProgress />
      </Grid>
    );
  }

  if (auth.isAuthenticated && auth.frontendUserRole === FrontendUserRole.Pending) {
    return (
      <Grid container position={'absolute'} justifyContent="center" top={'50%'}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <AxiosClientProvider>
      <NotistackSnackbarProvider maxSnack={5}>
        <SnackbarContextProvider>
          <ProgressContextProvider>
            <BrowserRouter>
              <Routes>
                {/* 新規条件作成 */}
                <Route
                  path="download"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <DownloadPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* 条件作成（シンプルな条件設定） */}
                <Route
                  path="simple_setting/new"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <SimpleQueryPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* 条件編集（シンプルな条件設定） */}
                <Route
                  path="simple_setting/new/:uuid"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <SimpleQueryPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* 条件作成（高度な条件設定（SQL）） */}
                <Route
                  path="sql_setting/new"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <SqlSettingPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* 条件編集（高度な条件設定（SQL）） */}
                <Route
                  path="sql_setting/new/:uuid"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <SqlSettingPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* 保存したデータ抽出条件 */}
                <Route
                  path="queries"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                        FrontendUserRole.Read,
                      ]}
                    >
                      <QueriesPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* データ抽出履歴 */}
                <Route
                  path="download_history"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                        FrontendUserRole.Read,
                        FrontendUserRole.NotAffiliation,
                      ]}
                    >
                      <DownloadHistoryPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* データソース（一覧） */}
                <Route
                  path="datasources"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[FrontendUserRole.SuperAdmin, FrontendUserRole.Admin]}
                    >
                      <DataSourcesPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* データソース（作成） */}
                <Route
                  path="datasource/new"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[FrontendUserRole.SuperAdmin, FrontendUserRole.Admin]}
                    >
                      <DataSourceSettingPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* データソース（編集） */}
                <Route
                  path="datasource/new/:connectionId"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[FrontendUserRole.SuperAdmin, FrontendUserRole.Admin]}
                    >
                      <DataSourceSettingPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* ユーザー（一覧） */}
                <Route
                  path="users"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                        FrontendUserRole.Read,
                        FrontendUserRole.NotAffiliation,
                      ]}
                    >
                      <UsersPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* ユーザー（作成） */}
                <Route
                  path="user/new"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[FrontendUserRole.SuperAdmin, FrontendUserRole.Admin]}
                    >
                      <UserPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* ユーザー（編集） */}
                <Route
                  path="user/new/:firstId"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[FrontendUserRole.SuperAdmin, FrontendUserRole.Admin]}
                    >
                      <UserPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* グループ（一覧） */}
                <Route
                  path="groups"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <GroupsPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* グループ（作成） */}
                <Route
                  path="group/new"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[FrontendUserRole.SuperAdmin, FrontendUserRole.Admin]}
                    >
                      <GroupPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* グループ（編集） */}
                <Route
                  path="group/new/:firstId"
                  element={
                    <RouteAuthGuard
                      allowUserRoles={[
                        FrontendUserRole.SuperAdmin,
                        FrontendUserRole.Admin,
                        FrontendUserRole.Edit,
                      ]}
                    >
                      <GroupPage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* ライセンス管理 */}
                <Route
                  path="license"
                  element={
                    <RouteAuthGuard allowUserRoles={[FrontendUserRole.SuperAdmin]}>
                      <LicensePage />
                    </RouteAuthGuard>
                  }
                ></Route>

                {/* ルート画面（サインイン） */}
                <Route index element={<SignIn />} />

                {/* サインイン */}
                <Route path="signin" element={<SignIn />} />

                {/* パスワードの再設定（メールアドレス入力） */}
                <Route path="passwordreset" element={<PasswordReset />} />

                {/* パスワードの再設定（新しいパスワード設定） */}
                <Route
                  path="forgotpassword-confirm/:userName"
                  element={<ForgotPasswordConfirm />}
                />
                {/* パスワード再設定完了 */}
                <Route path="completepasswordreset" element={<CompletePasswordReset />} />

                {/* パスワード変更（ヘッダーメニュー） */}
                <Route path="passwordchange" element={<PasswordChangePage />} />

                {/* 404 NotFound（ルートが設定されていないすべてのURL） */}
                <Route path="*" element={<NotFound />} />

                {/* 404 NotFound */}
                <Route path="notfound" element={<NotFound />}></Route>

                {/* 403 Forbidden */}
                <Route path="forbidden" element={<Forbidden />}></Route>
              </Routes>
            </BrowserRouter>
          </ProgressContextProvider>
        </SnackbarContextProvider>
      </NotistackSnackbarProvider>
    </AxiosClientProvider>
  );
}
