import { GetColumnsResp } from '../types/Responses';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWRImmutable from 'swr/immutable';

const config: SWRConfiguration = {};

export const useColumnsSwr = (connectionId: string, schemaN: string, tableN: string) => {
  const shouldFetch = connectionId && schemaN && tableN;
  const { data, error } = useSWRImmutable<GetColumnsResp>(
    shouldFetch
      ? `api/columns/?connection_id=${connectionId}&schema_n=${schemaN}&table_n=${tableN}`
      : null,
    fetcherKeysToCamel,
    config
  );

  return {
    columns: data,
    isLoading: !error && !data,
    isError: error,
  };
};
